import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { Fade } from "react-reveal"
import db from "../firebase-config"
import firebase from "firebase/compat/app"

const Menu = ({ data }) => {
  const [active, setActive] = useState(0)
  const [loading, setLoading] = useState(false)
  const [coffeeMenu, setCoffeeMenu] = useState([])

  const ref = db.collection("menu").orderBy("order")

  useEffect(() => {
    setLoading(true)
    ref.get().then(collections => {
      fetchCollection(collections)
    })
  }, [])

  const fetchCollection = collections => {
    const items = collections.docs.map(item => item.data())

    setCoffeeMenu(items)
    setLoading(false)
  }

  const images = {
    hero: convertToBgImage(getImage(data.hero)),
    item1: getImage(data.item1),
    item2: getImage(data.item2),
    item3: getImage(data.item3),

    one: getImage(data.one),
    two: getImage(data.two),
    three: getImage(data.three),
    four: getImage(data.four),
    five: getImage(data.five),
    six: getImage(data.six),
    seven: getImage(data.seven),
    eight: getImage(data.eight),
  }

  const filters = [
    {
      name: "all",
    },
    {
      name: "TRADITIONAL COFFEE DRINKS",
    },
    {
      name: "SIGNATURE DRINKS",
    },
    {
      name: "WHAT'S THE TEA",
    },
    {
      name: "REAL FRUIT SMOOTHIES",
    },
    {
      name: "BAGELS YOUR WAY",
    },
    {
      name: "FEEL GOOD BOWLS",
    },
    {
      name: "PASTRIES & SWEETS",
    },
  ]

  const menu = [
    images.one,
    images.two,
    images.three,
    images.four,
    images.five,
    images.six,
    images.seven,
    images.eight,
  ]

  return (
    <Layout>
      <Seo title="Menu" />
      <BackgroundImage
        {...images.hero}
        className="bg-primary-brown py-72 w-full"
      >
        <Fade bottom>
          <div>
            <div className="lg:text-8xl text-7xl text-white text-center font-prata gap-6">
              Menu.
            </div>
          </div>
        </Fade>
      </BackgroundImage>
      <section>
        <div className="container mx-auto lg:px-20 px-10 py-24">
          <Fade bottom cascade>
            <div>
              <div className="font-prata  md:text-5xl text-4xl text-center text-black mb-5">
                Our Spread
              </div>
            </div>
            <div>
              <div className="font-light  text-black text-base text-center max-w-4xl mx-auto">
                Vault Coffeehouse​ aims to spoil you by satisfying your
                cravings! Check out our variety of delicacies ranging from
                pastries, sweets, smoothies and many more goodies including our
                signature coffees & teas as well.
              </div>
            </div>
          </Fade>

          <div className="mt-14">
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4">
              {menu.map((item, index) => {
                return (
                  <>
                    <GatsbyImage image={item} alt="Vault coffee" />
                  </>
                )
              })}
            </div>
          </div>

          {/* <div className="my-14">
            <Fade bottom cascade>
              <div className="max-w-5xl mx-auto flex flex-wrap gap-5 justify-center">
                {filters.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => setActive(index)}
                      className={
                        active == index
                          ? "p-2 bg-primary-brown uppercase font-semibold md:text-sm text-xs cursor-pointer  text-white duration-200"
                          : "p-2 bg-primary-brown/30 uppercase font-semibold md:text-sm text-xs cursor-pointer hover:bg-primary-brown/50 duration-200"
                      }
                    >
                      {item.name}
                    </div>
                  )
                })}
              </div>
            </Fade>
          </div> */}
          {/* {loading ? (
            <Loader />
          ) : (
            <CoffeeMenuItems
              menu={coffeeMenu}
              active={active}
              filters={filters}
            />
          )} */}
        </div>
      </section>
    </Layout>
  )
}

const CoffeeMenuItems = props => {
  const cofeeMenu = props.menu
  const filters = props.filters
  const activeIndex = props.active
  const activeFilter = filters[activeIndex].name
  console.log(cofeeMenu)
  return (
    <>
      {activeFilter == "all" ? (
        <Fade bottom cascade>
          <div>
            <div className="mb-14">
              <div className="mb-8">
                <div className="uppercase mb-2 text-center font-semibold">
                  TRADITIONAL COFFEE DRINKS
                </div>
                <div className="font-light text-sm text-center">{""}</div>
              </div>
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
                {cofeeMenu &&
                  cofeeMenu.map((item, index) => {
                    return (
                      <div
                        className="lg:flex gap-5 items-center w-full"
                        key={index}
                      >
                        <div>
                          <img
                            src={item.image}
                            alt="vault coffee house"
                            className="lg:w-56 w-full"
                          />
                        </div>
                        <div className="w-full  ">
                          <div className="lg:text-left text-center">
                            <div className="uppercase text-primary-brown font-semibold lg:mt-0 mt-4">
                              {item.drinkName}
                            </div>
                            <div className="font-light text-sm mt-1 mb-4">
                              {item.flavour}
                            </div>
                            {item.sizes.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="lg:flex-col flex justify-center"
                                >
                                  <div className="mb-2 flex  items-center lg:w-auto w-1/2 ">
                                    <div className="font-light text-sm lg:w-1/5 w-1/2">
                                      {item.volume} oz
                                    </div>
                                    <hr className="border-[0.5px] border-black w-full" />
                                    <div className="font-light text-sm lg:w-1/5 w-1/2 text-end">
                                      ${item.price}
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </Fade>
      ) : (
        <Fade bottom cascade>
          <div>
            <div className="uppercase mb-8 text-center font-semibold">
              {activeFilter}
            </div>
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
              {cofeeMenu &&
                cofeeMenu
                  .filter(item => item.category == activeFilter)
                  .map((item, index) => {
                    return (
                      <Fade bottom cascade key={index}>
                        <div
                          className="lg:flex gap-5 items-center w-full"
                          key={index}
                        >
                          <div>
                            <img
                              src={item.image}
                              alt="vault coffee house"
                              className="lg:w-56 w-full"
                            />
                          </div>
                          <div className="w-full  ">
                            <div className="lg:text-left text-center">
                              <div className="uppercase text-primary-brown font-semibold lg:mt-0 mt-4">
                                {item.drinkName}
                              </div>
                              <div className="font-light text-sm mt-1 mb-4">
                                {item.flavour}
                              </div>
                              {item.sizes.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="lg:flex-col flex justify-center"
                                  >
                                    <div className="mb-2 flex  items-center lg:w-auto w-1/2 ">
                                      <div className="font-light text-sm lg:w-1/5 w-1/2">
                                        {item.volume} oz
                                      </div>
                                      <hr className="border-[0.5px] border-black w-full" />
                                      <div className="font-light text-sm lg:w-1/5 w-1/2 text-end">
                                        ${item.price}
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </Fade>
                    )
                  })}
            </div>
          </div>
        </Fade>
      )}
    </>
  )
}

const Loader = () => {
  return (
    <div className="flex justify-center animate-pulse">
      <div className="w-full ">
        <div className="h-4 w-1/3 bg-gray-100 mb-10 mx-auto"></div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
          <div className="flex lg:flex-row flex-col ">
            <div className="lg:w-56 w-full h-56 bg-gray-100  mr-5"></div>
            <div className="flex flex-col lg:w-1/2">
              <div className="w-full">
                <div className="w-full lg:mt-0 mt-5">
                  <div className="h-4 bg-gray-100 w-full mb-2"></div>
                </div>
                <div className="w-4/5">
                  <div className="h-4 bg-gray-100 w-full mb-2"></div>
                </div>
              </div>
              <div className="w-full mt-10 lg:block hidden">
                <div className="w-full">
                  <div className="h-4 bg-gray-100 w-full mb-2"></div>
                </div>
                <div className="w-4/5">
                  <div className="h-4 bg-gray-100 w-full mb-2"></div>
                </div>
              </div>
            </div>
          </div>
          <div className=" lg:flex-row flex-col lg:flex hidden">
            <div className="w-56 h-56 bg-gray-100  mr-5"></div>
            <div className="flex flex-col w-1/2">
              <div className="w-full">
                <div className="w-full lg:mt-0 mt-5">
                  <div className="h-4 bg-gray-100 w-full mb-2"></div>
                </div>
                <div className="w-4/5">
                  <div className="h-4 bg-gray-100 w-full mb-2"></div>
                </div>
              </div>
              <div className="w-full mt-10">
                <div className="w-full">
                  <div className="h-4 bg-gray-100 w-full mb-2"></div>
                </div>
                <div className="w-4/5">
                  <div className="h-4 bg-gray-100 w-full mb-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Menu

export const query = graphql`
  query ContactImages {
    hero: file(relativePath: { eq: "pages/about/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    one: file(relativePath: { eq: "menu/one.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    two: file(relativePath: { eq: "menu/two.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    three: file(relativePath: { eq: "menu/three.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    four: file(relativePath: { eq: "menu/four.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    five: file(relativePath: { eq: "menu/five.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    six: file(relativePath: { eq: "menu/six.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    seven: file(relativePath: { eq: "menu/seven.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    eight: file(relativePath: { eq: "menu/eight.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    item1: file(
      relativePath: { eq: "pages/home/online-shop-slider/grid1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    item2: file(
      relativePath: { eq: "pages/home/online-shop-slider/grid2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    item3: file(
      relativePath: { eq: "pages/home/online-shop-slider/grid3.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`
